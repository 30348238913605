<template>
  <div class="uk-width-1-1">
    <!-- Filter -->
    <div class="uk-width-1-1 uk-grid-small uk-child-width-1-4 uk-margin-top" uk-grid>
        <div class="uk-margin-remove">
            <form class="uk-width-1-1" @submit.prevent="doSearchLiveStreamApplicantLog">
                <label class="uk-form-label" for="fullname">Fullname</label>
                <div class="uk-width-1-1">
                    <div class="uk-inline uk-form-controls uk-width-1-1">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input 
                            id="fullname"
                            v-model="meta.fullname"
                            class="uk-input" 
                            type="search" 
                            aria-label="Search applicant" 
                            placeholder="Search applicant"
                        />
                    </div>
                </div>
            </form>
        </div>
        <div class="uk-margin-remove">
            <label class="uk-form-label" for="sortBy">Sort by</label>
            <div class="uk-form-controls uk-width-1-1">
                <select id="sortBy" class="uk-select" aria-label="Select" v-model="meta.sort_by"  @change.prevent="doSearchLiveStreamApplicantLog">
                    <option value="oldest">Oldest</option>
                    <option value="latest">Latest</option>
                </select>
            </div>
        </div>
    </div>
    <!-- End filter -->

    <!-- Applicant list table -->
    <div class="uk-margin-top uk-card uk-card-default">
        <div class="uk-overflow-auto">
            <table class="uk-table uk-table-striped uk-table-middle">
                <thead>
                    <tr>
                        <th class="uk-text-nowrap uk-table-shrink uk-text-center">No.</th>
                        <th class="uk-text-left uk-text-nowrap">User</th>
                        <th class="uk-text-left uk-text-nowrap">
                            <p class="uk-margin-remove">Roles</p>
                            <p class="uk-margin-remove">Type</p> 
                        </th>
                        <th class="uk-text-left uk-text-nowrap">Email</th>
                        <th class="uk-text-left uk-text-nowrap">
                            <p class="uk-margin-remove">Telephone</p>
                            <p class="uk-margin-remove">Number</p>
                        </th>
                        <th class="uk-text-left uk-text-nowrap">
                            <p class="uk-margin-remove">Applied to</p>
                            <p class="uk-margin-remove">(Company)</p>
                        </th>
                        <th class="uk-text-left uk-text-nowrap">
                            <p class="uk-margin-remove">Applied to</p>
                            <p class="uk-margin-remove">(Vacancy Title)</p>
                        </th>
                        <th class="uk-text-left uk-text-nowrap">
                            <p class="uk-margin-remove">Time Stamp</p>
                            <p class="uk-margin-remove">(WIB)</p>
                        </th>
                    </tr>
                </thead>
                <loading-table v-if="tableLoading" :colspan="8" />
                <template v-else>
                    <tbody v-if="liveStreamJobApplicantData.totalDocs > 0">
                        <tr v-for="(data, index) in liveStreamJobApplicantData.docs" :key="index">
                            <td class="uk-text-nowrap uk-table-shrink uk-text-center">{{ autoIncrementNumber({ pagingCounter: liveStreamJobApplicantData.pagingCounter, index }) }}</td>
                            <td class="uk-flex uk-flex-middle uk-flex-left uk-text-nowrap uk-text-left uk-text-nowrap">
                                <img 
                                    v-if="data && data.user_photos && data.user_photos.file_url" 
                                    :src="data.user_photos.file_url" 
                                    :alt="`${data.user_fullname} photos`" 
                                    class="uk-preserve-width uk-border-circle user-photos" 
                                />
                                <img
                                    v-else 
                                    :src="`${images}/person.svg`"
                                    alt="Person photos" 
                                    class="uk-preserve-width uk-border-circle user-photos" 
                                />
                                <p class="uk-margin-remove-vertical uk-margin-small-left">{{ data && data.user_fullname || '-' }}</p>
                            </td>
                            <td class="uk-text-left uk-text-nowrap">{{ data && data.user_roles_type || '-' }}</td>
                            <td class="uk-text-left uk-text-nowrap" :uk-tooltip="data && data.user_email">{{ data && preview({ data: data.user_email, limit: 20 }) || '-' }}</td>
                            <td class="uk-text-left uk-text-nowrap">{{ data && data.user_phone_number || '-' }}</td>
                            <td class="uk-text-left uk-text-nowrap" :uk-tooltip="data && data.company_name">{{ data && preview({ data: data.company_name, limit: 100 }) || '-' }}</td>
                            <td class="uk-text-left uk-text-nowrap" :uk-tooltip="data && data.job_title">{{ data && preview({ data: data.job_title, limit: 100 }) || '-' }}</td>
                            <td class="uk-text-left uk-text-nowrap">{{ data && formatterHourMinuteFromDate({ timestamp: data.timestamp }) || '-' }}</td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="8" />
                </template>
            </table>
        </div>
        <pagination
            :total-data="liveStreamJobApplicantData.totalDocs"
            :change-limit="changeLimit"
            :change-page="changePage"
        />
    </div>  
    <!-- End applicant list table -->
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import {
    // notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: 'ApplicantTab',
    components: {
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            internetConnection: true,
            tableLoading: false,
            liveStreamJobApplicantData: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0
            },
            meta: {
                limit: 10,
                page: 1,
                live_stream_id: '',
                fullname: null,
                sort_by: 'oldest'
            }
        };
    },
    watch: {
        async meta() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                await this.setLiveStreamJobApplicantData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        }
    },
    async mounted() {
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            await this.setLiveStreamJobApplicantData();
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        ...mapActions({
            getLiveStreamApplicantLogListPaginate: 'liveStreamApplicantLogV4/getLiveStreamApplicantLogListPaginate',
        }),
        async setLiveStreamJobApplicantData() {
            try {
                this.tableLoading = true;
                this.meta.live_stream_id = this.$route.params.id;
                const response = await this.getLiveStreamApplicantLogListPaginate(this.meta);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const docs = response.data.result?.docs || [];
                    const totalDocs = response.data.result?.totalDocs || 0;
                    const pagingCounter = response.data.result?.pagingCounter || 0;

                    this.liveStreamJobApplicantData.docs = docs;
                    this.liveStreamJobApplicantData.totalDocs = totalDocs;
                    this.liveStreamJobApplicantData.pagingCounter = pagingCounter;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async doSearchLiveStreamApplicantLog() {
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                this.tableLoading = true;
                this.meta.limit = 10;
                this.meta.page = 1;
                await this.setLiveStreamJobApplicantData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        autoIncrementNumber({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        preview({ data, limit }) {
            if (typeof data !== 'string') throw new Error('Data is not string');
            
            if (data.length < limit) {
                return data;
            } else {
                return data.substring(0, limit)+'...';
            }
        }, 
        formatterHourMinuteFromDate({ timestamp }) {
            const date = new Date(timestamp).toISOString();
            return formatter.hourMinuteFromDate(date);
        }, 
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
.user-photos {
    width: 100%;
    height: 100%;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
    object-position: center;
}
</style>
